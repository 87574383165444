<template>
  <v-dialog
    v-if="isLoaded"
    v-model="modalState"
    width="900"
    persistent
  >
    <v-card :class="highlightForm && 'highlight-required-fields'">
      <v-form
        v-model="formValid"
        :class="isSigned ? 'form-disabled' : ''"
        :disabled="isSigned"
      >
        <div
          ref="woundTitle"
          class="sticky-header"
        >
          <PatientInformation
            :title="`#${wound.wound_number} Wound Detail Entry`"
            :patient="patient"
          >
            <!-- image attachments -->
            <btn
              label="Images"
              :icon="icons.mdiFileImage"
              class="px-5"
              @click="attachmentTypeClicked"
            >
              <v-badge
                v-if="imageAttachmentCount"
                color="primary"
                :content="imageAttachmentCount"
              >
                Images
              </v-badge>
            </btn>
          </PatientInformation>

          <!-- attachment modal -->
          <attachment-modal
            :attachment-modal.sync="attachmentModal"
            :attachment-type.sync="attachmentType"
            attachment-type-id="3"
            :encounter-attachments.sync="encounterAttachments"
            :patient-id="patient.id"
            :signed="isSigned"
          ></attachment-modal>
        </div>

        <NoteSection label="Location &amp; Etiology">
          <!-- location -->
          <text-area
            ref="woundLoc"
            :value="wound.location.location_text"
            class="col-sm-7"
            label="Wound Location"
            rows="1"
            :append-icon="!!wound.location.location_text ? icons.mdiClose : null"
            :disabled="wound.followup_treatment && !!wound.location.location_text"
            readonly
            required
            @clear-warning="true"
            @click="locationModal = true"
            @click:append="clearLocation"
          ></text-area>

          <!-- etiology -->
          <select-box
            :value="treatment.etiology"
            :items="woundEtiologies"
            label="Etiology"
            item-value="title"
            class="col-sm-5"
            :previous-value="latestTreatment && latestTreatment.etiology ? previousEtiology: null"
            :previous-value-dos="previousVisitDate"
            required
            @change="etiologyChanged"
          ></select-box>

          <text-field
            v-if="treatment.etiology === 'Other'"
            :value="treatment.etiology_other"
            label="Enter other etiology..."
            class="col-sm-12"
            counter="900"
            max-length="900"
            required
            :previous-value="latestTreatment && latestTreatment.etiology_other
              ? latestTreatment.etiology_other : null"
            :previous-value-dos="previousVisitDate"
            :input-history-custom-styles="'padding-top: 18px;'"
            @blur="etiologyOtherChanged"
            @click:clear="etiologyOtherChanged"
          ></text-field>

          <!-- location modal -->
          <Location
            v-model="wound.location"
            :wound-id="wound.id"
            :modal-state.sync="locationModal"
          ></Location>
        </NoteSection>

        <NoteSection label="Facility Acquired Wound">
          <!-- facility acquired wound -->
          <checkbox
            :value="wound.is_wound_facility_acquired"
            label="Is wound facility-acquired?"
            class="col-sm-5"
            hide-details
            @change="facilityAcquired"
          ></checkbox>
          <icon-value
            :icon="icons.mdiInformation"
            class="col-sm-7"
            color="warning"
          >
            This information does not show on the final note
          </icon-value>
        </NoteSection>

        <div class="note-section mx-2">
          <!-- Alerts: Prevent validation (set color="error" and add required checkbox) -->
          <v-expand-transition>
            <div v-if="wound.is_healed && isProcedureDebridement">
              <alert color="error">
                Debridement procedure cannot be selected for this wound as it is indicated as being resolved.
                Please review the procedure selection or dimensions entered.
                <checkbox required></checkbox>
              </alert>
            </div>
          </v-expand-transition>

          <v-expand-transition>
            <div v-if="wound.location.location_text && treatment.procedure_id">
              <!-- (wound.location.location === 19 && treatment.procedure_id === 183)
            || (isLocationOnFoot && !isProcedureFoot && treatment.procedure_id !== 183) -->

              <alert
                v-if="!isLowerLimb && treatment.procedure_id === 195"
                color="error"
              >
                The selected procedure can only be applied to locations of leg, malleolus, foot or ball of foot.
                <checkbox required></checkbox>
              </alert>

              <alert
                v-if="!isTorsoAndLimbs && treatment.procedure_id === 177"
                color="error"
              >
                The selected procedure can only be applied to locations of leg, arm, chest, back, abdomen, or shoulder.
                <checkbox required></checkbox>
              </alert>

              <alert
                v-if="!isLocationOnFoot && isProcedureFoot && treatment.procedure_id !== 195"
                color="error"
              >
                The selected procedure can only be applied to locations of ball of foot, foot, toe, or heel.
                <checkbox required></checkbox>
              </alert>
            </div>
          </v-expand-transition>

          <v-expand-transition>
            <div
              v-if="Number(wound_treatment.wound_treatment_wound_size.granulation) === 100
                && !wound_treatment.wound_treatment_wound_size.unhealthy_granulation
                && !wound_treatment.preoperative_indications.some(x => x.title === 'Bioburden' || x.title === 'Biofilm')
                // checks if the treatment procedure is a debridement
                && isProcedureDebridement"
            >
              <alert color="error">
                Debridement on 100% granulation tissue is not permitted unless "Bioburden" or "Biofilm" are selected in the "Preoperative Indications" section or the "Unhealthy granulation tissue identified" checkbox is checked in the "Wound Measurements" section.
                <checkbox required></checkbox>
              </alert>
            </div>
          </v-expand-transition>

          <!-- Warnings: Allow validation -->
          <v-expand-transition>
            <div v-if="isLocationOnLeg && treatment.etiology === 'Pressure Injury/Ulcer' && isCirculationCompromised">
              <alert>
                As this patient is documented as having diabetes, PVD, PAD, or VSD and this is a lower extremity wound, please select a wound etiology accordingly.
                For example, It may be more accurate to select "Diabetes" instead of "Pressure Injury/Ulcer".
              </alert>
            </div>
          </v-expand-transition>

          <v-expand-transition>
            <div v-if="isLocationOnLeg && treatment.etiology === 'Pressure Injury/Ulcer' && !isCirculationCompromised">
              <alert>
                As this is a lower extremity pressure injury/ulcer and this patient is NOT documented as having diabetes, PVD, PAD, or VSD, it is advised to order an arterial ultrasound for this patient.
              </alert>
            </div>
          </v-expand-transition>
        </div>

        <NoteSection label="Procedure Performed">
          <select-box
            v-model="treatment.procedure_id"
            :items="woundProcedures"
            label="Procedure Performed"
            class="col-sm-12"
            required
            :disabled="visitLocation === 'Telemedicine'"
            :previous-value="previousProcedureTitle"
            :previous-value-dos="previousVisitDate"
          ></select-box>
        </NoteSection>

        <WoundMeasurements
          v-model="wound_treatment.wound_treatment_wound_size"
          :treatments="wound.treatments"
          :debridement-not-performed-reasons="wound_treatment.debridement_not_performed_reasons"
          :is-healed.sync="wound.is_healed"
          :is-etiology-pressure="isEtiologyPressure"
          :highlight-form="highlightForm"
          :is-procedure-debridement="isProcedureDebridement"
          :procedure-id="treatment.procedure_id"
          :wound-progress.sync="wound_treatment.progress"
          :latest-treatment="latestTreatment"
          @focusWoundLoc="focusWoundLoc"
        ></WoundMeasurements>

        <NoteSection
          v-if="!isQuickEntry
            && !debridementProcedurePerformed
            && (
              Number(wound_treatment.wound_treatment_wound_size.slough || 0)
              + Number(wound_treatment.wound_treatment_wound_size.necrotic_tissue || 0)
              >= 30
            )"
          label="Reasons For Debridement Not Performed"
        >
          <check-boxes
            v-model="wound_treatment.debridement_not_performed_reasons"
            :items="debridementNotPerformedReasons"
            :disabled="debridementProcedurePerformed"
            class="col-sm-12"
            columns="2"
            :previous-value="hasPreviousWoundTreatment && previousDebridementNotPerformedReasons
              ? previousDebridementNotPerformedReasons : null"
            :previous-value-dos="previousVisitDate"
            column-sort
            return-object
            required
          ></check-boxes>
          <text-area
            v-if="wound_treatment.debridement_not_performed_reasons.some(x => x.title === 'Other')"
            v-model="wound_treatment.debridement_not_performed_reason_other"
            label="Enter other reason debridement was not performed..."
            :disabled="debridementProcedurePerformed"
            class="col-sm-12"
            :previous-value="hasPreviousWoundTreatment && latestTreatment.wound_treatment.debridement_not_performed_reason_other
              ? latestTreatment.wound_treatment.debridement_not_performed_reason_other : null"
            :previous-value-dos="previousVisitDate"
            required
            counter="1600"
            maxlength="1600"
          ></text-area>
        </NoteSection>

        <NoteSection label="Wound Progress">
          <select-box
            v-model="wound_treatment.progress"
            :items="progresses"
            label="Wound Progress"
            class="col-sm-12"
            :previous-value="hasPreviousWoundTreatment ? previousWoundProgress : null"
            :previous-value-dos="previousVisitDate"
          >
          </select-box>

          <text-area
            v-if="wound_treatment.progress === 'Other'"
            v-model="wound_treatment.progress_other"
            label="Enter other wound progress..."
            class="col-sm-12"
            required
            counter="2200"
            maxlength="2200"
            :previous-value="hasPreviousWoundTreatment ? latestTreatment.wound_treatment.progress_other : null"
            :previous-value-dos="previousVisitDate"
          ></text-area>
        </NoteSection>

        <NoteSection
          label="Wound Description"
          heading
        ></NoteSection>

        <NoteSection
          v-if="!isQuickEntry"
          label="Odor"
        >
          <check-boxes
            v-model="wound_treatment.odors"
            :items="odors"
            class="col-sm-12 mt--2"
            columns="5"
            return-object
            radio-buttons
            clearable
            :previous-value="hasPreviousWoundTreatment && previousOdors
              ? previousOdors : null"
            :previous-value-dos="previousVisitDate"
          ></check-boxes>
        </NoteSection>

        <NoteSection label="Exudate">
          <check-boxes
            v-model="wound_treatment.exudate_amounts"
            :items="exudateAmounts"
            label="Amount"
            class="col-sm-12 mt--5"
            columns="5"
            return-object
            radio-buttons
            clearable
            :previous-value="hasPreviousWoundTreatment && previousExudateTypesAndAmount
              ? previousExudateTypesAndAmount : null"
            :previous-value-dos="previousVisitDate"
            @change="exudateAmountChanged"
          ></check-boxes>

          <v-divider></v-divider>

          <check-boxes
            v-model="wound_treatment.exudate_types"
            :items="exudateTypes"
            :disabled="wound_treatment.exudate_amounts.some(x => x.title === 'None')"
            label="Type"
            class="col-sm-12"
            columns="3"
            column-sort
            return-object
            hide-details
          ></check-boxes>
        </NoteSection>

        <NoteSection
          v-if="!isQuickEntry"
          label="Periwound"
        >
          <check-boxes
            v-model="wound_treatment.periwounds"
            :items="periwounds"
            :distinct="['Stable', 'Healthy']"
            class="col-sm-12 mt--1"
            columns="3"
            column-sort
            return-object
            :previous-value="hasPreviousWoundTreatment && previousPeriwounds
              ? previousPeriwounds : null"
            :previous-value-dos="previousVisitDate"
            hide-details
          ></check-boxes>
        </NoteSection>

        <NoteSection
          v-if="!isQuickEntry"
          label="Wound Edge"
        >
          <check-boxes
            v-model="wound_treatment.wound_edges"
            :items="woundEdges"
            distinct="Normal"
            class="col-sm-12 mt--1"
            columns="3"
            column-sort
            return-object
            hide-details
            :previous-value="hasPreviousWoundTreatment && previousWoundEdges
              ? previousWoundEdges : null"
            :previous-value-dos="previousVisitDate"
          ></check-boxes>
        </NoteSection>

        <NoteSection label="Signs Of Infection">
          <check-boxes
            v-model="wound_treatment.infection_signs"
            :items="infectionSigns"
            distinct="None"
            class="col-sm-12 mt--1"
            columns="3"
            column-sort
            return-object
            hide-details
            :previous-value="hasPreviousWoundTreatment && previousInfectionSigns
              ? previousInfectionSigns : null"
            :previous-value-dos="previousVisitDate"
            :required="!isQuickEntry"
          ></check-boxes>
        </NoteSection>

        <WoundWheel
          v-model="wound_treatment.wound_treatment_undermining"
          :tunneling-wheel.sync="wound_treatment.wound_treatment_tunneling"
          :sinus-tract-wheel.sync="wound_treatment.wound_treatment_sinus_tract"
          :is-signed="isSigned"
        ></WoundWheel>

        <NoteSection
          v-if="!isQuickEntry"
          label="Pain Level"
        >
          <radio-buttons
            v-model="wound_treatment.pain_level"
            :items="painLevel"
            class="col-sm-12"
            clearable
            row
          ></radio-buttons>
        </NoteSection>

        <NoteSection
          v-if="!isQuickEntry"
          label="Wound Description Summary"
        >
          <text-area
            v-model="wound_treatment.wound_description_summary"
            label="Wound description summary"
            class="col-sm-12"
            counter="8500"
            maxlength="8500"
            ai
            :previous-value="hasPreviousWoundTreatment ? latestTreatment.wound_treatment.wound_description_summary : null"
            :previous-value-dos="previousVisitDate"
            @click:prepend="generateWoundDescription"
          ></text-area>
        </NoteSection>

        <NoteSection
          v-if="!isQuickEntry && debridementProcedurePerformed"
          label="Preoperative Indications"
        >
          <check-boxes
            v-model="wound_treatment.preoperative_indications"
            :items="preoperativeIndications"
            class="col-sm-12 mt--1"
            columns="2"
            column-sort
            return-object
            hide-details
            :previous-value="hasPreviousWoundTreatment ? previousPreoperativeIndications : null"
            :previous-value-dos="previousVisitDate"
            :required="!!treatment.procedure_id"
          ></check-boxes>
          <text-area
            v-if="isOtherPreoperativeIndication"
            v-model="wound_treatment.preoperative_indication_other"
            label="Enter other preoperative indications..."
            class="col-sm-12"
            required
            counter="1300"
            maxlength="1300"
            :previous-value="hasPreviousWoundTreatment ? latestTreatment.wound_treatment.preoperative_indication_other : null"
            :previous-value-dos="previousVisitDate"
          ></text-area>
        </NoteSection>

        <NoteSection
          label="Wound Treatment"
          heading
        ></NoteSection>

        <NoteSection label="Dressings Used">
          <select-box
            v-model="wound_treatment.dressing_used_1"
            :items="dressings"
            label="Dressing Used"
            class="col-sm-6"
            :required="!isQuickEntry && this.$custom.isEmpty(wound_treatment.dressing_used_other)"
            :previous-value="hasPreviousWoundTreatment ? latestTreatment.wound_treatment.dressing_used_1 : null"
            :previous-value-dos="previousVisitDate"
            @input="firstDressingUsedChanged"
          >
          </select-box>

          <select-box
            v-model="wound_treatment.dressing_used_2"
            :items="dressings.filter(x => x.title !== 'None')"
            :disabled="wound_treatment.dressing_used_1 === 'None'"
            label="Additional Dressing Used"
            class="col-sm-6"
            :previous-value="hasPreviousWoundTreatment ? latestTreatment.wound_treatment.dressing_used_2 : null"
            :previous-value-dos="previousVisitDate"
          >
          </select-box>

          <text-area
            v-model="wound_treatment.dressing_used_other"
            :disabled="wound_treatment.dressing_used_1 === 'None'"
            :required="!isQuickEntry && this.$custom.isEmpty(wound_treatment.dressing_used_1)"
            class="col-sm-12"
            label="Enter other dressings used..."
            counter="1200"
            maxlength="1200"
            :previous-value="hasPreviousWoundTreatment ? latestTreatment.wound_treatment.dressing_used_other : null"
            :previous-value-dos="previousVisitDate"
          ></text-area>

          <text-area
            v-if="isDakinDressing"
            v-model="wound_treatment.reason_for_dakins_solution"
            label="Enter explanation why and how long the patient will be receiving Dakin’s solution..."
            class="col-sm-12"
            :previous-value="hasPreviousWoundTreatment ? latestTreatment.wound_treatment.reason_for_dakins_solution : null"
            :previous-value-dos="previousVisitDate"
            required
          ></text-area>
        </NoteSection>

        <NoteSection label="Reason for Ongoing Debridement">
          <check-boxes
            v-model="computedReasonForOngoingDebridement"
            :items="reasonForOngoingDebridement"
            class="col-sm-12"
            :previous-value="hasPreviousWoundTreatment ? computedLatestTreatmentReasonForOngoingDebridement: null"
            :previous-value-dos="previousVisitDate"
            multiple
            columns="3"
            column-sort
          >
          </check-boxes>

          <text-area
            v-if="wound_treatment.ongoing_debridement_reason.includes('Other')"
            v-model="wound_treatment.ongoing_debridement_reason_other"
            label="Enter other reasons for ongoing debridement..."
            class="col-sm-12"
            :previous-value="hasPreviousWoundTreatment ? latestTreatment.wound_treatment.ongoing_debridement_reason_other : null"
            :previous-value-dos="previousVisitDate"
            required
            counter="1200"
            maxlength="1200"
          ></text-area>
        </NoteSection>

        <OperativeNote
          v-if="!isQuickEntry"
          v-model="treatment.operative_note"
          :operative-note-wizard.sync="treatment.operative_note_wizard"
          :wound-treatment.sync="wound_treatment"
          :treatments="wound.treatments"
          :treatment-id="treatment.id"
          :highlight-form="highlightForm"
          :patient="patient"
          :procedure-id="treatment.procedure_id"
          is-wound-treatment
          :required="debridementProcedurePerformed"
          :previous-op-note="latestTreatment && latestTreatment.operative_note ? latestTreatment.operative_note : null"
          :previous-dos="previousVisitDate"
        ></OperativeNote>

        <NoteSection
          v-if="isQuickEntry"
          label="Quick Entry"
          heading
        ></NoteSection>

        <QuickEntry
          v-if="isQuickEntry"
          :current-treatment.sync="treatment.current_treatment"
          :qent_notes.sync="treatment.qent_notes"
          :operative-note.sync="treatment.operative_note"
          :treatments="wound.treatments"
        ></QuickEntry>

        <v-card-actions>
          <!-- done -->
          <btn
            v-if="isSigned"
            label="Done"
            color="secondary"
            :icon="icons.mdiCheck"
            @click="done"
          ></btn>
          <!-- cancel -->
          <btn
            v-else
            label="Cancel"
            color="secondary"
            :icon="icons.mdiCancel"
            @click="cancel"
          ></btn>
          <!-- delete -->
          <btn
            v-if="isNewTreatment && !isSigned"
            label="Delete"
            color="error"
            :icon="icons.mdiTrashCan"
            rem:disabled="treatment.can_be_deleted !== true"
            @click="deleteTreatment"
          ></btn>

          <v-spacer></v-spacer>

          <!-- save draft -->
          <btn
            v-if="!isSigned"
            label="Save Draft"
            :icon="icons.mdiContentSaveEdit"
            color="success"
            @click="saveDraft"
          ></btn>

          <!-- validate/save -->
          <btn
            v-if="!isSigned"
            label="Validate / Save"
            :icon="icons.mdiCheckDecagram"
            @click="validateSave"
          ></btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import AttachmentModal from '@/components/notes/encounter/AttachmentModal.vue'
import PatientInformation from '@/components/notes/encounter/PatientInformation.vue'
import Location from '@/components/notes/wound-detail/treatments/sections/Location.vue'
import OperativeNote from '@/components/notes/wound-detail/treatments/sections/OperativeNote.vue'
import QuickEntry from '@/components/notes/wound-detail/treatments/sections/QuickEntry.vue'
import WoundMeasurements from '@/components/notes/wound-detail/treatments/sections/WoundMeasurements.vue'
import WoundWheel from '@/components/notes/wound-detail/treatments/sections/WoundWheel.vue'
import {
  mdiCancel,
  mdiCheck,
  mdiCheckDecagram,
  mdiClose,
  mdiContentSave,
  mdiContentSaveEdit,
  mdiFileImage,
  mdiInformation,
  mdiTrashCan,
} from '@mdi/js'
import { mapGetters } from 'vuex'
import InputHistory from '@/components/elements/InputHistory.vue'
const woundInitial = {
  wound_number: null,
  practice_type_id: null,
  is_wound_facility_acquired: false,
  is_wound_not_recent: false,
  wound_acquired_facility_id: null,
  location: {},

  // I believe this should only be at the treatment level, not the wound level
  // operative_note_wizard: {},
}
const inside = {
  inside_quadrant_1: null,
  inside_quadrant_2: null,
  inside_quadrant_3: null,
  inside_quadrant_4: null,
}
const outside = {
  outside_1_oclock: null,
  outside_2_oclock: null,
  outside_3_oclock: null,
  outside_4_oclock: null,
  outside_5_oclock: null,
  outside_6_oclock: null,
  outside_7_oclock: null,
  outside_8_oclock: null,
  outside_9_oclock: null,
  outside_10_oclock: null,
  outside_11_oclock: null,
  outside_12_oclock: null,
}
const woundTreatmentInitial = {
  wound_treatment_wound_size: {
    primary_length: null,
    primary_width: null,
    primary_depth: null,
    primary_area: null,
    slough: null,
    granulation: null,
    necrotic_tissue: null,
    hyper_granulation: null,
    eschar: null,
    epithelial: null,
    dermal: null,
    pre_debridement_total: null,
    unhealthy_granulation: false,
    wound_stage: null,
    post_debridement_length: null,
    post_debridement_width: null,
    post_debridement_depth: null,
    post_debridement_area: null,
    post_debridement_percent: null,
    post_debridement_area_debrided: null,
    wound_size_text: null,
  },
  progress: null,
  progress_other: null,
  odors: [],
  exudate_amounts: [],
  exudate_types: [],
  periwounds: [],
  wound_edges: [],
  infection_signs: [],
  wound_treatment_undermining: { ...inside, ...outside },
  wound_treatment_tunneling: { ...outside },
  wound_treatment_sinus_tract: { ...outside },
  pain_level: null,
  wound_description_summary: null,
  preoperative_indications: [],
  preoperative_indication_other: null,
  dressing_used_1: null,
  dressing_used_2: null,
  dressing_used_other: null,
  reason_for_dakins_solution: null,
  debridement_not_performed_reasons: [],
  debridement_not_performed_reason_other: null,
  ongoing_debridement_reason: [],
  ongoing_debridement_reason_other: null,
  current_treatment: null,
  qent_notes: null,
  operative_note_wizard: {},
  operative_note: null,
}

export default {
  components: {
    PatientInformation,
    AttachmentModal,
    Location,
    WoundMeasurements,
    WoundWheel,
    OperativeNote,
    QuickEntry,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    modalState: {
      type: Boolean,
      default: false,
    },
    patient: {
      type: Object,
      default: null,
    },
    isSigned: {
      type: [Boolean, Number],
      default: null,
    },
    isCirculationCompromised: {
      type: Boolean,
      default: null,
    },
    encounterAttachments: {
      type: Array,
      default: null,
    },
    encounterId: {
      type: String,
      default: null,
    },
    visitLocation: {
      type: String,
      default: null,
    },
    quickEntry: {
      type: Boolean,
      default: null,
    },
    latestTreatment: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isDomUpdated: false,
      formValid: false,
      highlightForm: false,
      attachmentModal: false,
      attachmentType: null,
      locationModal: false,
      operativeNoteWizardModal: false,
      wheelSize: 300,
      wound: {},
      treatment: {},
      wound_treatment: {},
      painLevel: [
        { title: '0', id: 0 },
        { title: '1', id: 1 },
        { title: '2', id: 2 },
        { title: '3', id: 3 },
        { title: '4', id: 4 },
        { title: '5', id: 5 },
        { title: '6', id: 6 },
        { title: '7', id: 7 },
        { title: '8', id: 8 },
        { title: '9', id: 9 },
        { title: '10', id: 10 },
      ],
      onLeg: [4, 6, 19, 23, 26, 27, 30, 42, 45, 48, 49], // SWC-508 & SWC-509
      onFoot: ['ball of foot', 'foot', 'heel', 'toe'], // SWC-515: Ball of Foot, Foot
      onLowerLimb: ['leg', 'malleolus', 'foot', 'ball of foot'], // SWC-513: Leg, Malleolus, Foot, Ball of Foot
      onTorsoAndLimbs: ['leg', 'arm', 'chest', 'back', 'abdomen', 'shoulder'], // SWC-514: Leg, Arm, Chest, Back, Abdomen, Shoulder
      icons: {
        mdiCheck,
        mdiFileImage,
        mdiCancel,
        mdiClose,
        mdiCheckDecagram,
        mdiContentSaveEdit,
        mdiContentSave,
        mdiTrashCan,
        mdiInformation,
      },
      timeoutId: null,
    }
  },
  beforeDestroy() {
    // Cleanup the timeout to avoid memory leaks
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  },
  computed: {
    ...mapGetters('baseData', ['woundProcedures']),
    ...mapGetters('encounters', [
      'debridementNotPerformedReasons',
      'dressings',
      'woundEtiologies',
      'exudateAmounts',
      'exudateTypes',
      'infectionSigns',
      'odors',
      'periwounds',
      'preoperativeIndications',
      'progresses',
      'reasonForOngoingDebridement',
      'woundEdges',
    ]),
    isLoaded() {
      return (this.wound && typeof this.wound.wound_number !== 'undefined')
    },
    isQuickEntry() {
      return (this.$route.query.quick || this.$route.query.quick === 'true') && this.quickEntry
    },
    isNewTreatment() {
      return this.encounterId === this.treatment.encounter_id
    },
    isEtiologyPressure() {
      return this.fieldContains(this.treatment.etiology, 'pressure')
        || this.fieldContains(this.treatment.etiology_other, 'pressure')
    },
    procedureTitle() {
      return this.$store.getters['baseData/procedureFromId'](this.treatment.procedure_id)
    },
    previousProcedureTitle() {
      if (this.latestTreatment && this.latestTreatment.procedure_id) return this.$store.getters['baseData/procedureFromId'](this.latestTreatment.procedure_id)

      return null
    },
    isOtherPreoperativeIndication() {
      return this.wound_treatment.preoperative_indications.some(x => x.title === 'Other')
    },
    isProcedureDebridement() {
      return this.fieldContains(this.procedureTitle, 'debrid')
    },
    isProcedureFoot() {
      return this.fieldContains(this.procedureTitle.toLowerCase(), 'foot')
    },
    isDakinDressing() {
      return this.fieldContains(this.wound_treatment.dressing_used_1, 'dakin')
        || this.fieldContains(this.wound_treatment.dressing_used_2, 'dakin')
        || this.fieldContains(this.wound_treatment.dressing_used_other, 'dakin')
    },
    isLocationOnLeg() {
      return this.onLeg.includes(this.wound.location.location)
    },
    isLocationOnFoot() {
      return this.onFoot.some(i => this.wound.location.location_text.toLowerCase().includes(i))
    },
    isLowerLimb() {
      return this.onLowerLimb.some(i => this.wound.location.location_text.toLowerCase().includes(i))
    },
    isTorsoAndLimbs() {
      return this.onTorsoAndLimbs.some(i => this.wound.location.location_text.toLowerCase().includes(i))
    },
    imageAttachmentCount() {
      if (!Array.isArray(this.encounterAttachments)) return false

      return this.encounterAttachments.reduce((count, item) => count + (item.attachment_type === 'images'), 0)
    },
    debridementProcedurePerformed() {
      const procedure = this.$store.getters['baseData/procedureFromId'](this.treatment.procedure_id)

      return procedure && procedure.toLowerCase().includes('debrid')
    },
    require_vitals() {
      return !this.wound_treatment.infection_signs.some(x => x.title === 'None')
    },
    computedReasonForOngoingDebridement: {
      get() {
        if (!this.wound_treatment.ongoing_debridement_reason) return []

        return Array.isArray(this.wound_treatment.ongoing_debridement_reason)
          ? this.wound_treatment.ongoing_debridement_reason
          : this.wound_treatment.ongoing_debridement_reason.split(',')
      },
      set(newValue) {
        this.wound_treatment.ongoing_debridement_reason = newValue
        if (!newValue.includes('Other')) this.wound_treatment.ongoing_debridement_reason_other = null
      },
    },
    computedLatestTreatmentReasonForOngoingDebridement() {
      const treatment = this.latestTreatment?.wound_treatment

      if (!treatment || !treatment.ongoing_debridement_reason) {
        return null
      }

      const reasons = Array.isArray(treatment.ongoing_debridement_reason)
        ? treatment.ongoing_debridement_reason
        : treatment.ongoing_debridement_reason.split(',')

      // Other get's replaced with the input from ongoing_debridement_reason_other
      const formattedReasons = reasons.map(reason => reason.trim() === 'Other' ? treatment.ongoing_debridement_reason_other : reason.trim())

      return formattedReasons.join(', ')
    },
    previousVisitDate() {
      return this.latestTreatment && this.latestTreatment.encounter && this.latestTreatment.encounter.visit_date
        ? this.latestTreatment.encounter.visit_date : null
    },
    hasPreviousWoundTreatment() {
      return this.latestTreatment && this.latestTreatment.wound_treatment
    },
    previousWoundProgress() {
      if (this.latestTreatment?.wound_treatment?.progress?.includes('Other')) return `Other: ${this.latestTreatment.wound_treatment.progress_other}`

      return this.latestTreatment.wound_treatment.progress
    },
    previousEtiology() {
      if (this.latestTreatment?.etiology?.includes('Other')) return `Other: ${this.latestTreatment.etiology_other}`

      return this.latestTreatment.etiology
    },
    previousPreoperativeIndications() {
      const treatment = this.latestTreatment?.wound_treatment

      if (!treatment || !treatment.preoperative_indications) {
        return null
      }
      const formattedPreoperativeIndications = treatment?.preoperative_indications?.map(preoperative_indication => preoperative_indication.title.includes('Other') ? treatment.preoperative_indication_other : preoperative_indication.title)

      return formattedPreoperativeIndications.join(', ')
    },
    previousDebridementNotPerformedReasons() {
      const treatment = this.latestTreatment?.wound_treatment

      if (!treatment || !treatment.debridement_not_performed_reasons) return null

      const formattedPreoperativeIndications = treatment?.debridement_not_performed_reasons?.map(debr_not_performed_reason => debr_not_performed_reason.title.includes('Other') ? treatment.debridement_not_performed_reason_other : debr_not_performed_reason.title)

      return formattedPreoperativeIndications.join(', ')
    },
    previousInfectionSigns() {
      const treatment = this.latestTreatment?.wound_treatment

      if (!treatment || !treatment.infection_signs) return null

      return treatment?.infection_signs?.map(infSign => infSign.title).join(', ')
    },
    previousWoundEdges() {
      const treatment = this.latestTreatment?.wound_treatment

      if (!treatment || !treatment.wound_edges) return null

      return treatment?.wound_edges?.map(woundEdge => woundEdge.title).join(', ')
    },
    previousPeriwounds() {
      const treatment = this.latestTreatment?.wound_treatment

      if (!treatment || !treatment.periwounds) return null

      return treatment?.periwounds?.map(periwound => periwound.title).join(', ')
    },
    previousExudateTypesAndAmount() {
      const treatment = this.latestTreatment?.wound_treatment
      let message = ''

      if (!treatment) return null

      if (treatment?.exudate_amounts && treatment?.exudate_amounts.length > 0) message += `Amount: ${treatment?.exudate_amounts?.map(exAmount => exAmount.title).join(', ')}\n`
      if (treatment?.exudate_types && treatment?.exudate_types.length > 0) message += `Types: ${treatment?.exudate_types?.map(exType => exType.title).join(', ')}`

      return message || null
    },
    previousOdors() {
      const treatment = this.latestTreatment?.wound_treatment

      if (!treatment || !treatment.odors) return null

      return treatment?.odors?.map(odor => odor.title).join(', ')
    },
  },
  watch: {
    modalState() {
      this.highlightForm = false
      if (!this.modalState) {
        // Reset wound and treatment objects
        this.wound = {}
        this.treatment = {}

        // Always set this property back to false after closing the modal
        // to prevent certain field watchers from running unintentionally
        // next time the user opens the wound detail modal.
        this.isDomUpdated = false

        // Scroll to top of modal
        const modalTitle = this.$refs.woundTitle
        if (modalTitle) {
          modalTitle.scrollIntoView(true)
        }
      } else {
        // Simulate data loading, use this property to prevent some watchers from
        // running unintentionally when the woundDetail modal is opened.
        if (this.timeoutId) {
          clearTimeout(this.timeoutId) // Clear any existing timeout
        }

        this.timeoutId = setTimeout(() => {
          this.isDomUpdated = true
        }, 1000)
      }
    },
    value() {
      if (this.modalState && this.value && this.value.treatments) {
        const treatmentIndex = this.value.treatments.findIndex(x => x.encounter_id === this.encounterId && !x.deleted)
        this.wound = {
          ...this.$lodash.cloneDeep(woundInitial),
          ...this.$lodash.cloneDeep(this.value),
          treatments: Array.from(this.value.treatments),
        }
        if (!this.wound.location) this.wound.location = {}

        if (!this.treatment.operative_note_wizard) this.treatment.operative_note_wizard = {}

        this.wound.treatments[treatmentIndex] = {
          ...this.$lodash.cloneDeep(this.value.treatments[treatmentIndex]),
          wound_treatment: {
            ...this.$lodash.cloneDeep(woundTreatmentInitial),
            ...((this.value.treatments[treatmentIndex]
                && this.value.treatments[treatmentIndex].wound_treatment)
                && this.$lodash.cloneDeep(this.value.treatments[treatmentIndex].wound_treatment)
            ),
          },
        }

        // Telemedicine can't have a treatment, so it must be [999] None.
        if (this.visitLocation === 'Telemedicine') {
          this.wound.treatments[treatmentIndex].procedure_id = 999

          // Also, clear the op note text.
          this.wound.treatments[treatmentIndex].operative_note = null
        }

        // Short references
        this.treatment = this.wound.treatments[treatmentIndex]
        this.wound_treatment = this.wound.treatments[treatmentIndex].wound_treatment
      }
    },

    'wound_treatment.wound_treatment_undermining': {
      deep: true,
      handler() {
        this.generateWoundDescription(false)
      },
    },
    'wound_treatment.wound_treatment_tunneling': {
      deep: true,
      handler() {
        this.generateWoundDescription(false)
      },
    },
    'wound_treatment.wound_treatment_sinus_tract': {
      deep: true,
      handler() {
        this.generateWoundDescription(false)
      },
    },
    'wound_treatment.odors': {
      handler() {
        this.generateWoundDescription(false)
      },
    },
    'wound_treatment.exudate_amounts': {
      handler() {
        this.generateWoundDescription(false)
      },
    },
    'wound_treatment.exudate_types': {
      handler() {
        this.generateWoundDescription(false)
      },
    },
    'wound_treatment.periwounds': {
      handler() {
        this.generateWoundDescription(false)
      },
    },
    'wound_treatment.wound_edges': {
      handler() {
        this.generateWoundDescription(false)
      },
    },
    'wound_treatment.pain_level': {
      handler() {
        this.generateWoundDescription(false)
      },
    },
    'wound_treatment.wound_treatment_wound_size.unhealthy_granulation': {
      handler() {
        this.generateWoundDescription(false)
      },
    },

    // Clear reasons for no debridement if applicable --> procedure not set to none, sum of slough and necrotic tissue is less than 30).
    'treatment.procedure_id': {
      handler(newValue) {
        if (newValue !== 999) {
          this.clearReasonsForNoDebridement()
        }
      },
    },
    'wound_treatment.wound_treatment_wound_size.slough': {
      handler(newValue) {
        if (Number(newValue || 0)
        + Number(this.wound_treatment.wound_treatment_wound_size.necrotic_tissue || 0)
        < 30) {
          this.clearReasonsForNoDebridement()
        }
      },
    },
    'wound_treatment.wound_treatment_wound_size.necrotic_tissue': {
      handler(newValue) {
        if (Number(newValue || 0)
          + Number(this.wound_treatment.wound_treatment_wound_size.slough || 0)
          < 30) {
          this.clearReasonsForNoDebridement()
        }
      },
    },
  },
  mounted() {
  },
  updated() {
    // Disabling this as it's no longer necessary since we're no longer showing the loading modal when opening the wound details.
    // It's also causing a huge delay for every input when there's extensive data in the store.
    // this.$store.dispatch('loading', false)
  },
  methods: {
    focusWoundLoc() {
      this.$nextTick(() => {
        this.$refs.woundLoc.$el.querySelector('textarea').focus()
      })
    },
    facilityAcquired(newState) {
      console.log(this.wound.is_wound_facility_acquired, newState)

      this.wound.is_wound_facility_acquired = newState
      if (newState) {
        this.$root.confirm({
          html: true,
          title: 'Report wound was acquired at facility?',
          body: `Wound you like to report that this wound was acquired at <b>${this.$store.getters['facilities/getById'](this.treatment.place_of_service_id).title}</b>?`,
          confirm: 'Yes',
        }).then(result => {
          if (result) {
            this.wound.wound_acquired_facility_id = this.treatment.place_of_service_id
            this.$root.confirm({
              title: 'Wound not recent?',
              body: "Was this wound acquired prior to SWC's initial visit of the patient?",
              cancel: 'No',
              confirm: 'Yes',
            }).then(confirm => {
              this.wound.is_wound_not_recent = confirm
            })
          } else {
            this.$nextTick(() => {
              this.wound.is_wound_facility_acquired = false
              this.wound.is_wound_not_recent = false
              this.wound.wound_acquired_facility_id = null
            })
          }
        })
      } else {
        this.$root.confirm({
          title: 'Are you sure?',
          body: 'Are you sure you want to unmark this wound as facility-acquired?',
          confirm: 'Yes',
        }).then(result => {
          if (result) {
            this.wound.is_wound_not_recent = false
            this.wound.wound_acquired_facility_id = null
          } else {
            this.$nextTick(() => {
              this.wound.is_wound_facility_acquired = true
            })
          }
        })
      }
    },
    generateWoundDescription(emptyWarning = true) {
      if (this.modalState && this.isDomUpdated) {
        let woundDescription = ''

        // Generate the wound description summary based on SWC-654
        const orderByPropertyWithQuadrant = [
          'inside_quadrant_1',
          'outside_1_oclock',
          'outside_2_oclock',
          'outside_3_oclock',
          'inside_quadrant_2',
          'outside_4_oclock',
          'outside_5_oclock',
          'outside_6_oclock',
          'inside_quadrant_3',
          'outside_7_oclock',
          'outside_8_oclock',
          'outside_9_oclock',
          'inside_quadrant_4',
          'outside_10_oclock',
          'outside_11_oclock',
          'outside_12_oclock',
        ]
        const orderByProperty = [
          'outside_1_oclock',
          'outside_2_oclock',
          'outside_3_oclock',
          'outside_4_oclock',
          'outside_5_oclock',
          'outside_6_oclock',
          'outside_7_oclock',
          'outside_8_oclock',
          'outside_9_oclock',
          'outside_10_oclock',
          'outside_11_oclock',
          'outside_12_oclock',
        ]
        const definitions = {
          inside_quadrant_1: " cm from 12 o'clock to 3 o'clock",
          inside_quadrant_2: " cm from 3 o'clock to 6 o'clock",
          inside_quadrant_3: " cm from 6 o'clock to 9 o'clock",
          inside_quadrant_4: " cm from 9 o'clock to 12 o'clock",
          outside_1_oclock: " cm at 1 o'clock",
          outside_2_oclock: " cm at 2 o'clock",
          outside_3_oclock: " cm at 3 o'clock",
          outside_4_oclock: " cm at 4 o'clock",
          outside_5_oclock: " cm at 5 o'clock",
          outside_6_oclock: " cm at 6 o'clock",
          outside_7_oclock: " cm at 7 o'clock",
          outside_8_oclock: " cm at 8 o'clock",
          outside_9_oclock: " cm at 9 o'clock",
          outside_10_oclock: " cm at 10 o'clock",
          outside_11_oclock: " cm at 11 o'clock",
          outside_12_oclock: " cm at 12 o'clock",
        }
        let temp = ''
        orderByPropertyWithQuadrant.forEach(property => {
          const woundTreatmentUndermining = this.treatment.wound_treatment.wound_treatment_undermining[property]
          if (woundTreatmentUndermining !== null && woundTreatmentUndermining !== undefined) {
            temp += `\t${woundTreatmentUndermining + definitions[property]}\n`
          }
        })
        if (temp !== '') { woundDescription += `UNDERMINING:\n${temp}` }
        temp = ''
        orderByProperty.forEach(property => {
          const woundTreatmentTunneling = this.treatment.wound_treatment.wound_treatment_tunneling[property]
          if (woundTreatmentTunneling !== null && woundTreatmentTunneling !== undefined) {
            temp += `\t${woundTreatmentTunneling + definitions[property]}\n`
          }
        })
        if (temp !== '') { woundDescription += `TUNNELLING:\n${temp}` }
        temp = ''
        orderByProperty.forEach(property => {
          const woundTreatmentSinusTract = this.treatment.wound_treatment.wound_treatment_sinus_tract[property]
          if (woundTreatmentSinusTract !== null && woundTreatmentSinusTract !== undefined) {
            temp += `\t${woundTreatmentSinusTract + definitions[property]}\n`
          }
        })
        if (temp !== '') { woundDescription += `SINUS TRACT:\n${temp}` }

        if (this.treatment.wound_treatment.odors.length > 0) {
          woundDescription += `ODOR: ${this.$custom.toList(this.treatment.wound_treatment.odors)}\n`
        }
        if (this.treatment.wound_treatment.exudate_amounts.length > 0 || this.treatment.wound_treatment.exudate_types.length > 0) {
          woundDescription += `EXUDATE: ${this.$custom.toList([...this.treatment.wound_treatment.exudate_amounts, ...this.treatment.wound_treatment.exudate_types])}\n`
        }
        if (this.treatment.wound_treatment.periwounds.length > 0) {
          woundDescription += `PERIWOUND: ${this.$custom.toList(this.treatment.wound_treatment.periwounds)}\n`
        }
        if (this.treatment.wound_treatment.wound_edges.length > 0) {
          woundDescription += `WOUND EDGE: ${this.$custom.toList(this.treatment.wound_treatment.wound_edges)}\n`
        }
        if (!this.$custom.isEmpty(this.treatment.wound_treatment.pain_level)) {
          woundDescription += `PAIN: ${this.treatment.wound_treatment.pain_level}/10\n`
        }
        if (this.treatment.wound_treatment.wound_treatment_wound_size.unhealthy_granulation) {
          woundDescription += '\n\nUnhealthy granulated tissue identified within the wound!'
        }
        this.wound_treatment.wound_description_summary = this.$custom.clean(woundDescription, 'Wound Description', emptyWarning)
      }
    },
    clearLocation() {
      this.wound.location = { wound_id: this.wound.id }
    },
    fieldContains(field, contains) {
      return !field ? false : field.toLowerCase().includes(contains)
    },
    etiologyChanged(newEtiology) {
      this.etiologyLogic(this.treatment.etiology, newEtiology, false)
    },
    etiologyOtherChanged(event) {
      if (this.treatment.etiology_other !== event.target.value) {
        this.etiologyLogic(this.treatment.etiology_other, event.target.value, true)
      }
    },
    etiologySetNew(newEtiology, isOther) {
      if (isOther) {
        this.treatment.etiology_other = newEtiology
      } else {
        this.treatment.etiology = newEtiology
        if (this.treatment.etiology !== 'Other') {
          this.treatment.etiology_other = null
        }
      }
    },
    etiologyReset(currentEtiology, isOther) {
      if (isOther) {
        // This is done to make sure we change it to a different value so the field updates
        this.treatment.etiology_other = (currentEtiology === null ? '' : null)
        this.$nextTick(() => {
          this.treatment.etiology_other = currentEtiology
        })
      } else {
        this.treatment.etiology = null
        this.$nextTick(() => {
          this.treatment.etiology = currentEtiology
          if (this.treatment.etiology !== 'Other') {
            this.treatment.etiology_other = null
          }
        })
      }
    },
    etiologyLogic(currentEtiology, newEtiology, isOther) {
      let defaultAction = true

      // Wound etiology is not allowed to contain the substring 'resolv'
      if (this.fieldContains(newEtiology, 'resolv')) {
        this.etiologyReset(currentEtiology, isOther)
        defaultAction = false
        this.$store.dispatch('notify', { value: 'Mention of wound resolution is not allowed in the wound etiology.', color: 'error' })
      }

      // Previously 'pressure' etiology, no longer pressure, so clear wound_stage and notify user
      if (this.isEtiologyPressure
        && this.wound_treatment.wound_treatment_wound_size.wound_stage
        && this.isEtiologyPressure !== this.fieldContains(newEtiology, 'pressure')
      ) {
        this.wound_treatment.wound_treatment_wound_size.wound_stage = null
        this.$store.dispatch('notify', { value: 'Wound staging cleared as it is no longer categorized as a pressure injury.', color: 'warning' })
      }

      // Change to Etiology causes ongoing_debridement_reason to be cleared
      if (!!this.wound_treatment.ongoing_debridement_reason || !!this.wound_treatment.ongoing_debridement_reason_other) {
        defaultAction = false // Because the action is based on the confirmation selected
        this.$root.confirm({
          title: 'Are you sure you wish to change etiology?',
          body: 'Changing the etiology will reset your reasons for ongoing debridement.',
        }).then(result => {
          if (result) {
            this.etiologySetNew(newEtiology, isOther)
            this.wound_treatment.ongoing_debridement_reason = []
            this.wound_treatment.ongoing_debridement_reason_other = null
          } else {
            this.etiologyReset(currentEtiology, isOther)
          }
        })
      }

      // No special conditions, so change to the new Etiology
      if (defaultAction) {
        this.etiologySetNew(newEtiology, isOther)
      }
    },
    firstDressingUsedChanged() {
      if (this.wound_treatment.dressing_used_1 === 'None') {
        this.wound_treatment.dressing_used_2 = null
        this.wound_treatment.dressing_used_other = null
      }
    },
    attachmentTypeClicked() {
      this.attachmentType = 'images'
      this.attachmentModal = true
    },
    exudateAmountChanged() {
      if (this.wound_treatment.exudate_amounts.some(x => x.title === 'None')) {
        this.wound_treatment.exudate_types = []
      }
    },
    operativeNoteWizardClicked() {
      this.operativeNoteWizardModal = true
    },
    done() {
      this.$emit('update:modal-state', false)
    },
    cancel() {
      this.$root.confirm({
        title: 'Discard Treatment Changes?',
        body: 'Are you sure you wish to discard treatment changes?',
        cancel: 'No',
        confirm: 'Discard',
        confirmColor: 'error',
      }).then(result => {
        if (result) {
          this.done()
        }
      })
    },
    deleteTreatment() {
      this.$root.confirm({
        title: 'Delete Treatment Record?',
        subTitle: 'Warning: This operation cannot be undone!',
        body: 'Are you sure you wish to delete this treatment record?',
        confirm: 'Delete',
        confirmIcon: this.icons.mdiTrashCan,
        confirmColor: 'error',
      }).then(result => {
        if (result) {
          if (this.treatment.can_be_deleted) {
            const treatmentIndex = this.wound.treatments.findIndex(x => x.id === this.treatment.id)
            this.wound.treatments.splice(treatmentIndex, 1)
            this.treatment = {}
            if (this.wound.treatments.length === 0 && this.wound.can_be_deleted) this.wound.delete_wound = true
          } else {
            this.wound.updated = true
            this.treatment.updated = true
            this.treatment.deleted = true
          }
          this.$emit('input', this.wound)
          this.done()
        }
      })
    },
    saveDraft() {
      this.treatment.updated = true
      this.wound.updated = true
      this.treatment.is_validated = false
      this.treatment.wound_treatment.ongoing_debridement_reason = this.treatment.wound_treatment.ongoing_debridement_reason.toString()
      this.treatment.require_vitals = this.require_vitals
      this.treatment.modified = this.$custom.utcNow()
      this.wound.modified = this.$custom.utcNow()
      this.$emit('input', this.wound)
      this.done()
    },
    validateSave() {
      if (this.formValid) {
        if (!this.isQuickEntry) {
          this.treatment.is_validated = true
        }
        this.treatment.modified = this.$custom.utcNow()
        this.wound.modified = this.$custom.utcNow()
        this.treatment.updated = true
        this.wound.updated = true
        this.treatment.require_vitals = this.require_vitals
        this.treatment.wound_treatment.ongoing_debridement_reason = this.treatment.wound_treatment.ongoing_debridement_reason.toString()
        this.$emit('input', this.wound)
        this.done()
      } else {
        this.$root.confirm({
          titleIconColor: 'error',
          title: 'Wound Treatment Doesn\'t Pass Validation!',
          body: 'Please complete all required and non-validated fields. You will now automatically scroll to the first non-validated field.',
          cancel: false,
        }).then(() => {
          // Highlight non-validated fields and smooth-scroll to first section
          this.highlightForm = false
          this.$nextTick(() => {
            this.highlightForm = true
          })
          const field = this.$refs.woundTitle.closest('.v-dialog').querySelector('.required-field, .v-input--has-state')
          if (field) {
            const section = field.closest('.note-section')
            const top = section.closest('.v-dialog')
            if (section && top) {
              top.scrollTo({ top: section.offsetTop - 85, behavior: 'smooth' })
            }
          }
        })
      }
    },
    clearReasonsForNoDebridement() {
      if (this.modalState) {
        this.wound_treatment.debridement_not_performed_reasons = []
        this.wound_treatment.debridement_not_performed_reason_other = null
      }
    },
    handlePreviousOpNote() {
      this.$refs.opNotePrevious.open()
    },
    handlePreviousWoundDescriptionSummary() {
      this.$refs.previousWoundDescriptionSummary.open()
    },
  },
}
</script>
